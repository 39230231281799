import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const statusBadge = defineStyle((props) => {
  return {
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: props.color,
    padding: '4px 8px 4px 8px'
  };
});

export const badgeTheme = defineStyleConfig({
  variants: {
    statusBadge
  }
});
